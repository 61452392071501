import { NgModule } from '@angular/core';
import { FormFactoryUiComponent } from './form-factory-ui.component';
import { LogoComponent } from './logo/logo.component';

@NgModule({
  declarations: [FormFactoryUiComponent, LogoComponent],
  imports: [
  ],
  exports: [
    FormFactoryUiComponent,
    LogoComponent
  ]
})
export class FormFactoryUiModule { }
